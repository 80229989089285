const TrackingManager = {
  storageKeys: ["gclid", "gbraid", "wbraid"],

  init() {
    const urlParams = new URLSearchParams(window.location.search);

    this.storageKeys.forEach((key) => {
      const value = urlParams.get(key);
      if (value) {
        sessionStorage.setItem(key, value);
      }
    });
  },

  getTrackingParam(param) {
    return sessionStorage.getItem(param) || new URLSearchParams(window.location.search).get(param) || "";
  }
};

export default TrackingManager;
