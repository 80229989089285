import { Controller } from "@hotwired/stimulus"
import "intl-tel-input/build/css/intlTelInput.css"

export default class extends Controller {
  static values = { countryCode: String }
  static targets = ["input"]

  connect() {
    import("intl-tel-input").then(({default: intlTelInput}) => {
      this.telInput = intlTelInput(this.inputTarget, {
        allowDropdown: true,
        autoHideDialCode: true,
        formatOnDisplay: true,
        initialCountry: this.countryCodeValue,
        nationalMode: false,
        onlyCountries: ["us","gb","in","ca","de","au","ph","ng","za","es","fr","pt","nl","mx","br","ie","it","tr","id","ke","sg","my","jp","pk","se","hk","ru","ar","hu","ch","pl","rs","co","vn","lr","cl","cn","at","th","il"],
        loadUtils: () => import(/* webpackIgnore: true */ "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/utils.js")
      })
    })
  }
}
