/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require.context("../fonts", true)

// console.log('Hello World from Webpacker')

import "@hotwired/turbo-rails"
Turbo.session.drive = false

import Rails from '@rails/ujs';
Rails.start();

import "stylesheets/application" // Add this line
import "controllers/index" // Add this line
import "global/facebook"
import "global/cookie_consent"
import "lazysizes"

import TrackingManager from "modules/tracking_manager"

document.addEventListener("DOMContentLoaded", () => {
  TrackingManager.init();
});

// Defines an action, 'redirect' as a workaround for breaking out of a turbo frame
// when the server returns a redirect to a turbo request requiring a full page refresh.
// Taken from a discussion on a turbo-rails PR here -
// https://github.com/hotwired/turbo-rails/pull/367#issuecomment-1934729149

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};
