import { Controller } from "@hotwired/stimulus";
import TrackingManager from "modules/tracking_manager";

export default class extends Controller {
  connect() {
    this.insertTrackingParams();
  }

  insertTrackingParams() {
    const trackingKeys = ["gclid", "gbraid", "wbraid"];
    const form = this.element;

    trackingKeys.forEach((key) => {
      const value = TrackingManager.getTrackingParam(key);

      if (value && !form.querySelector(`input[name="${key}"]`)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = value;
        form.appendChild(hiddenField);
      }
    });
  }
}
