import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this._awayHandler = this._awayHandler.bind(this)
    document.addEventListener('click', this._awayHandler)

    this._escapeHandler = this._escapeHandler.bind(this)
    document.addEventListener('keydown', this._escapeHandler)

    this._toggleBodyClasses()
  }

  disconnect() {
    document.removeEventListener('click', this._awayHandler)
    document.removeEventListener('keydown', this._escapeHandler)
    this._toggleBodyClasses()
  }

  close() {
    this.element.remove()
  }

  _awayHandler(event) {
    if (this.element == event.target) {
      document.removeEventListener('click', this._awayHandler)
      this.close()
    }
    return true
  }

  _escapeHandler(event) {
    if (typeof event.key !== 'string') return

    if (event.key.toLowerCase() === 'escape') {
      document.removeEventListener('keydown', this._escapeHandler)
      event.stopPropagation()
      this.close()
    }
    return true
  }

  _toggleBodyClasses() {
    document.querySelector("html").classList.toggle("overflow-hidden")
    document.querySelector("body").classList.toggle("overflow-y-scroll")
  }
}
